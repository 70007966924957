import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const HeaderOne = styled(motion.h1)`
  color: ${(props) => props.color || ""};
  margin: ${(props) => props.margin || "0.67em 0 0.67em 0"};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    `}

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}
`;

export const HeaderThree = styled(motion.h3)`
  color: ${(props) => props.color || ""};
  margin: ${(props) => props.margin || "0.67em 0 0.67em 0"};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    `}

  ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}
`;

export const HeaderTwo = styled(motion.h2)`
  color: ${(props) => props.color || ""};
  margin: ${(props) => props.margin || "0.67em 0 0.67em 0"};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    `}

  ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}
`;

export const HeaderFour = styled(motion.h4)`
  color: ${(props) => props.color || ""};
  margin: ${(props) => props.margin || "0.67em 0 0.67em 0"};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    `}

  ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}

    ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
`;

export const Text = styled(motion.p)`
  font-size: ${(props) => props.fontSize || "20px"};
  font-family: ${(props) => props.fontFamily || "cube"};
  color: ${(props) => props.color || ""};
  margin: ${(props) => props.margin || "0.67em 0 0.67em 0"};
  ${(props) =>
    props.center &&
    css`
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    `}

  ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}
`;

export const BigHeader = styled(motion.h1)`
  font-size: 4rem;
  margin: ${(props) => props.margin || "auto"};

  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

  ${(props) =>
    props.letterSpacing &&
    css`
      letter-spacing: ${props.letterSpacing};
    `}

    ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}
`;

export const ButtonText = styled(motion.h1)`
  letter-spacing: 3px;
  margin: ${(props) => props.margin || "auto"};
  font-family: ${(props) => props.fontFamily || "blair"};
  font-size: ${(props) => props.fontSize || "2rem"};

  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

  ${(props) =>
    props.letterSpacing &&
    css`
      letter-spacing: ${props.letterSpacing};
    `}

    ${(props) =>
    props.light &&
    css`
      color: #fff;
    `}
`;
