import React from "react";
import { motion } from "framer-motion";

import { theme } from "../../theme";
import { ButtonText, HeaderFour, HeaderOne } from "./typography";
import { Button } from "./Button";
import Grid from "@bit/mui-org.material-ui.grid";
import styled from "styled-components";
import { transition } from "../organisms/GameControls";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { isMobile } from "react-device-detect";

const Input = styled.input`
  margin: 0px;
  padding: 17px 20px;
  color: inherit;
  border: none;
  font-size: 1.7em;
  background-color: rgba(0, 0, 0, 0.2);
  border: ${(props) => `2px solid ${props.theme.colors.secondary}`};
  color: #fff;
  font-family: "blair";
  font-size: 1.2em;
  width: 100%;
  box-shadow: 0 5px 0 ${(props) => props.theme.colors.secondaryDarker};
  box-sizing: border-box;

  :focus {
    outline: 0;
  }

  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: "Arvo";
    font-size: 1em;
  }
  ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: "Arvo";
    font-size: 1em;
  }
`;

const EnterCode = ({ setCode, checkCode, incorrect }) => {
  const { small } = useWindowDimensions();

  return (
    <motion.div
      initial={false}
      animate={{
        opacity: 1,
        y: "0%",
        transition: { ...transition },
      }}
      exit={{ opacity: 0 }}
      transition={{ ...transition }}
      style={
        isMobile
          ? {
              backgroundColor: theme.colors.primary,
              boxShadow: "0 7px 0 #7A1518",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
            }
          : {
              backgroundColor: theme.colors.primary,
              boxShadow: "0 7px 0 #7A1518",
              width: "60vw",
              height: "300px",
              top: 0,
              bottom: 0,
              margin: "auto",
              position: "absolute",
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
            }
      }
    >
      <Grid
        container
        style={{ textAlign: "center" }}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <HeaderOne light margin="0px 0px 20px 0px">
            ENTER YOUR CODE
          </HeaderOne>
        </Grid>
        <Grid item xs={small && 11}>
          <Input
            style={{ marginBottom: small ? "1em" : 0 }}
            onChange={(e) => setCode(e.target.value)}
          />
        </Grid>
        <Grid item xs={small && 11}>
          <Button fullWidth onClick={() => checkCode()} padding="19px 20px">
            <ButtonText fontSize="1em">ENTER</ButtonText>
          </Button>
        </Grid>
        <Grid item xs={11}>
          {incorrect && (
            <HeaderFour margin="20px 0px 0px 0px" light>
              INCORRECT CODE
            </HeaderFour>
          )}
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default EnterCode;
