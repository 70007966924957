import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { Box } from "./GridcellPerimeter";
import { useVerticalDrop } from "../../store/games/verticalDrop";
import { getRandomArbitrary } from "../../gameFunctions/calculate";

export const OutputBox = styled(motion.div)`
  position: absolute;
  width: 12%;
  height: 12%;
  background-color: ${(props) => props.theme.colors.secondary};
  box-sizing: border-box;
  z-index: 2;
  top: 80%;
`;

export const InputBox = styled(motion.div)`
  position: absolute;
  width: 10%;
  height: 10%;
  border: 0 none;
  background-color: ${(props) => props.theme.colors.primary};
  z-index: 3;
`;

export const GridcellVerticalDrop = ({ result }) => {
  return (
    <Box
      style={{
        background:
          "radial-gradient(circle,rgba(190, 223, 255, 1) 0%,rgba(223, 240, 255, 1) 68%,rgba(255, 255, 255, 1) 100% )",
        border: result !== undefined ? "none" : "1px solid #64a4d3",
      }}
    />
  );
};

export const VerticalDropOutput = React.forwardRef((props, ref) => {
  const [positionRight] = useState(getRandomArbitrary(10, 60));
  return (
    <OutputBox
      ref={ref}
      animate={{
        width: "12%",
        height: "12%",
      }}
      style={{ top: `${70}%`, left: `${positionRight}%` }}
    />
  );
});

export const VerticalDropInput = React.forwardRef((props, ref) => {
  const { setStopped, stopped } = useVerticalDrop();
  const offset =
    props.gameskeleton.offsetWidth - props.gameskeleton.offsetWidth * 0.08 - 22;

  useEffect(() => {
    if (props.started) {
      props.controls.start("keyframes");
    }
  }, [props.controls, props.simplified, props.started]);

  const variants = {
    keyframes: {
      right: [-10, offset - 10],
      transition: {
        duration: props.simplified ? 1.4 : 1,
        loop: Infinity,
        ease: [0, 0, 0, 0],
      },
    },
    test: {
      top: ["0%", "66%"],
      transition: {
        duration: 1.7,
      },
    },
  };
  useEffect(() => {
    if (props.started) {
      props.controls.set({ top: "0%" });
      props.controls.start("keyframes");
    }
  }, [props.started, props.controls]);

  return (
    <InputBox
      onAnimationComplete={(definition) => {
        definition === "test" && setStopped(true);
      }}
      ref={ref}
      variants={variants}
      animate={props.controls}
      style={{ y: "50%" }}
    />
  );
});
