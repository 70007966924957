import React from "react";
import { motion } from "framer-motion";

import logo from "../../assets/images/logo.png";
import { HeaderOne } from "./typography";

const Logo = ({ small, xs }) => {
  return (
    <motion.div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        style={{
          width: small ? "250px" : "500px",
          height: small ? "150px" : "200px",
        }}
        alt="logo"
        src={logo}
      />
      {!xs && (
        <HeaderOne
          style={{
            position: "absolute",
            color: "#fff",
            top: 300,
            textAlign: "center",
            lineHeight: 1.7,
          }}
        >
          CHOOSE YOUR <br /> GAME
        </HeaderOne>
      )}
    </motion.div>
  );
};

export default Logo;
