import React, { useEffect, useState } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence } from "framer-motion";
import useSound from "use-sound";
import ReactGa from "react-ga";

import { Container } from "../../atoms/Container";
import Logo from "../../atoms/Logo";
import { useStore } from "../../../store";
import GameBox from "../../atoms/GameBox";
import useResultReset from "../../../hooks/useReset";
import EnterCode from "../../atoms/EnterCode";
import openingMusic from "../../../assets/audio/opening.wav";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const NewMenu = () => {
  const { games, granted, setGranted, soundSettingOpening } = useStore();
  const [preview, setPreview] = useState(false);
  const [incorrect, setIncorrect] = useState(false);
  const reset = useResultReset();
  const [code, setCode] = useState();
  const [playOpeningMusic] = useSound(openingMusic, {
    volume: soundSettingOpening,
  });
  const { small, xs } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const checkCode = () => {
    if (code) {
      let correctCode = "UK729021";
      if (code.toUpperCase() === correctCode) {
        localStorage.setItem("code", correctCode);
        setGranted(true);
        playOpeningMusic();
      } else {
        setIncorrect(true);
      }
    }
  };

  useEffect(() => {
    localStorage.getItem("code") === "UK729021"
      ? setGranted(true)
      : setGranted(false);
    const timeOut = setTimeout(() => {
      setPreview(true);
    }, 1000);
    return () => {
      clearInterval(timeOut);
    };
  }, []);

  return (
    <Container>
      <Grid style={{ marginBottom: !xs && 300 }} container justify="center">
        <AnimatePresence exitBeforeEnter>
          {!granted ? (
            <Grid key="code" item lg={6} md={8} xs={12} xl={6}>
              <EnterCode
                incorrect={incorrect}
                checkCode={checkCode}
                setCode={setCode}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <Logo xs={xs} small={small} />
              </Grid>
              <Grid key="games" item>
                <Grid
                  container
                  justify="center"
                  spacing={6}
                  xs={12}
                  md={10}
                  lg={10}
                  style={{ margin: "0 auto", marginTop: !small ? 50 : 0 }}
                >
                  {games.map((game, i) => (
                    <Grid item xs={6} md={4}>
                      <GameBox preview={preview} game={game} key={i} i={i} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </AnimatePresence>
      </Grid>
    </Container>
  );
};

export default NewMenu;
