import _ from "lodash";

export const generateSequence = () => {
  const rounds = getRandomArbitrary(10, 13);
  const sequence = [];
  for (let i = 1; i < rounds; i++) {
    sequence.push(generateLine(i));
  }
  let total = 0;
  for (let i = 0; i < sequence.length; i++) {
    const element = sequence[i];
    total += element.length;
  }
  return {
    sequence: sequence,
    total: total,
  };
};

function generateLine(i) {
  switch (i) {
    case 1:
      return _.sampleSize(_.range(0, 7), getRandomArbitrary(2, 5));
    case 2:
      return _.sampleSize(_.range(8, 15), getRandomArbitrary(2, 5));
    case 3:
      return _.sampleSize(_.range(16, 23), getRandomArbitrary(2, 5));
    case 4:
      return _.sampleSize(_.range(24, 31), getRandomArbitrary(2, 5));
    case 5:
      return _.sampleSize(_.range(32, 39), getRandomArbitrary(2, 5));
    case 6:
      return _.sampleSize(_.range(40, 47), getRandomArbitrary(2, 5));
    case 7:
      return _.sampleSize(_.range(0, 7), getRandomArbitrary(2, 5));
    case 8:
      return _.sampleSize(_.range(24, 31), getRandomArbitrary(2, 5));
    case 9:
      return _.sampleSize(_.range(8, 15), getRandomArbitrary(2, 5));
    case 10:
      return _.sampleSize(_.range(40, 47), getRandomArbitrary(2, 5));
    case 11:
      return _.sampleSize(_.range(16, 23), getRandomArbitrary(2, 5));
    case 12:
      return _.sampleSize(_.range(32, 39), getRandomArbitrary(2, 5));
    default:
      break;
  }
}

export function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}
