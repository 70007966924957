import React, { useState, useEffect, useRef } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import { useCircumvent } from "../../store/games/circumvent";
import { theme } from "../../theme";
import { checkPerimeterResult } from "../../gameFunctions/perimeter";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getRandomArbitrary } from "../../gameFunctions/calculate";

const SideTimer = ({ time, reverse, simplified = { simplified } }) => {
  let totalTime = simplified ? 17 : 15;

  return (
    <div
      style={{
        width: "30px",
        height: "100%",
        border: `3px ${theme.colors.primary} solid`,
        padding: "5px",
      }}
    >
      <motion.div
        animate={{
          height: reverse
            ? `${Math.abs(
                Math.round((Math.abs(-time / totalTime) - 1) * 100)
              )}%`
            : `${Math.round((time / totalTime) * 100)}%`,
          backgroundColor: theme.colors.primary,
        }}
      />
    </div>
  );
};

const PortraitTimer = ({ time, reverse, simplified = { simplified } }) => {
  let totalTime = simplified ? 17 : 15;

  return (
    <motion.div
      style={{
        height: 20,
        y: 152,
        border: `3px ${theme.colors.primary} solid`,
        padding: "5px",
      }}
    >
      <motion.div
        animate={{
          height: "100%",
          width: reverse
            ? `${Math.abs(
                Math.round((Math.abs(-time / totalTime) - 1) * 100)
              )}%`
            : `${Math.round((time / totalTime) * 100)}%`,
          backgroundColor: theme.colors.primary,
        }}
      />
    </motion.div>
  );
};

const TargetBox = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        boxSizing: "content-box",
        width: "10%",
        height: "10%",
        border: props.portrait ? "3px grey solid" : "7px grey solid",
        position: "absolute",
        top: !props.start ? 10 : "",
        bottom: props.portrait
          ? props.start
            ? 75
            : ""
          : props.start
          ? "22.5%"
          : "",
        right: 0,
        left: 0,
        margin: "auto",
      }}
    />
  );
});

const PlayerBox = React.forwardRef((props, ref) => {
  return (
    <motion.div
      ref={ref}
      animate={{
        x: props.playerPosition.x,
        y: props.playerPosition.y,
      }}
      style={{
        width: "9%",
        height: "9%",
        background: "blue",
        position: "absolute",
        bottom: props.portrait ? 80 : "24%",
        right: 0,
        left: 0,
        margin: "auto",
      }}
    />
  );
});

const Obstacle = ({
  position,
  player,
  setStarted,
  resultRef,
  started,
  setShowControls,
}) => {
  const { setResultCircumvent } = useCircumvent();
  const ref = useRef();
  function collisionDetection() {
    if (
      ref.current &&
      player.current &&
      started &&
      resultRef.current === undefined
    ) {
      const rect1 = ref.current.getBoundingClientRect();
      const rect2 = player.current.getBoundingClientRect();
      if (
        rect1.x < rect2.x + rect2.width &&
        rect1.x + rect1.width > rect2.x &&
        rect1.y < rect2.y + rect2.height &&
        rect1.y + rect1.height > rect2.y
      ) {
        setResultCircumvent(false);
        setStarted(false);
        setShowControls(false);
        resultRef.current = false;
      } else {
        requestAnimationFrame(collisionDetection);
      }
    }
  }

  useEffect(() => {
    if (started) {
      let animation = requestAnimationFrame(collisionDetection);
      return () => cancelAnimationFrame(animation);
    }
  }, [started]);

  return (
    <motion.div
      ref={ref}
      style={{
        width: "5%",
        height: "5%",
        background: theme.colors.primary,
        position: "absolute",
        top: 10,
        y: position.y,
        x: position.x,
        right: 0,
        left: 0,
        margin: "auto",
      }}
    />
  );
};

const GridcellCircumvent = ({
  started,
  setStarted,
  setPlayerPosition,
  playerPosition,
  time,
  setTime,
  resultRef,
  timeRef,
  simplified,
  setShowControls,
}) => {
  const {
    obstacles,
    setResultCircumvent,
    result,
    obstaclesMobile,
  } = useCircumvent();
  const playerRef = useRef();
  const targetRef = useRef();
  const { portrait } = useWindowDimensions();
  const [randomObstacle] = useState(getRandomArbitrary(0, 3));
  const [randomObstacleMobile] = useState(getRandomArbitrary(0, 1));
  const { small } = useWindowDimensions();
  document.onkeydown = checkKey;

  useEffect(() => {
    const checkResult = () => {
      if (
        playerRef.current &&
        targetRef.current &&
        result === undefined &&
        started
      ) {
        if (
          checkPerimeterResult(
            targetRef.current.getBoundingClientRect(),
            playerRef.current.getBoundingClientRect(),
            small ? 3 : 7
          )
        ) {
          setResultCircumvent(true);
          setStarted(false);
          resultRef.current = true;
          setShowControls(false);
        } else {
          requestAnimationFrame(checkResult);
        }
      }
    };

    let animation = requestAnimationFrame(checkResult);
    return () => cancelAnimationFrame(animation);
  }, [result, started]);

  function checkKey(e) {
    let speed = portrait ? 5 : 8;
    if (result === undefined && !e.repeat && started) {
      if (e.keyCode === 87 || e.keyCode === 38) {
        Math.abs(playerPosition.y) < 500 &&
          setPlayerPosition({
            ...playerPosition,
            y: playerPosition.y - speed,
          });
      } else if (e.keyCode === 83 || e.keyCode === 40) {
        Math.abs(playerPosition.y) > 0 &&
          setPlayerPosition({
            ...playerPosition,
            y: playerPosition.y + speed,
          });
      } else if (e.keyCode === 188 || e.keyCode === 37) {
        Math.abs(playerPosition.x) < 206 &&
          setPlayerPosition({
            ...playerPosition,
            x: playerPosition.x - speed,
          });
      } else if (e.keyCode === 190 || e.keyCode === 39) {
        playerPosition.x !== 200 &&
          setPlayerPosition({
            ...playerPosition,
            x: playerPosition.x + speed,
          });
      }
    }
  }

  useEffect(() => {
    let interval;
    if (started) {
      interval = setInterval(() => {
        if (
          timeRef.current <= 0 ||
          resultRef.current !== undefined ||
          !started
        ) {
          setStarted(false);
          if (timeRef.current <= 0 && resultRef.current === undefined) {
            setResultCircumvent(false);
            resultRef.current = false;
            setShowControls(false);
          }
          clearInterval(interval);
        } else {
          setTime((time) => (time -= 0.1));
          timeRef.current -= 0.1;
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [started, resultRef.current]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
      }}
    >
      <div
        style={{
          height: "80%",
          borderBottom: `3px ${theme.colors.primary} solid`,
        }}
      >
        <Grid
          style={{ height: "100%", paddingLeft: "2rem", paddingRight: "2rem" }}
          container
          justify={portrait ? "justify-center" : "space-between"}
          alignItems="center"
        >
          {portrait ? (
            <Grid item xs={12}>
              <PortraitTimer simplified={simplified} reverse time={time} />
            </Grid>
          ) : (
            <>
              <Grid style={{ height: "60%" }} item>
                <SideTimer simplified={simplified} time={time} />
              </Grid>
              <Grid style={{ height: "60%" }} item>
                <SideTimer simplified={simplified} reverse time={time} />
              </Grid>
            </>
          )}
        </Grid>
        {portrait
          ? obstaclesMobile[randomObstacleMobile].map((obstacle, i) => {
              return (
                <Obstacle
                  setShowControls={setShowControls}
                  started={started}
                  resultRef={resultRef}
                  setStarted={setStarted}
                  player={playerRef}
                  key={i}
                  position={obstacle}
                />
              );
            })
          : obstacles[randomObstacle].map((obstacle, i) => {
              return (
                <Obstacle
                  setShowControls={setShowControls}
                  started={started}
                  resultRef={resultRef}
                  setStarted={setStarted}
                  player={playerRef}
                  key={i}
                  position={obstacle}
                />
              );
            })}
        <TargetBox portrait={portrait} ref={targetRef} />
        <TargetBox portrait={portrait} start />
        <PlayerBox
          portrait={portrait}
          ref={playerRef}
          playerPosition={playerPosition}
        />
        {result !== undefined && (
          <video
            autoPlay
            style={{
              width: "100%",
              height: "auto",
              position: "absolute",
              top: 0,
              zIndex: 5,
            }}
            loop
            muted
            playsInline
          >
            <source src={`/${result ? "win" : "lose"}.mp4`} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
};

export default GridcellCircumvent;
