import React, { useEffect, useState } from "react";
import { motion, useCycle } from "framer-motion";
import _ from "lodash";

import { useReplicate } from "../../store/games/replicate";
import { theme } from "../../theme";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Box = ({ i, x, y, red, phase, startAnswering, started, randomShown }) => {
  const { small } = useWindowDimensions();
  const { setBoxCount, boxCountFilled, setBoxCountFilled } = useReplicate();
  const [color, cycleColor] = useCycle(
    "rgba(223, 240, 255, 1)",
    theme.colors.primary,
    "blue"
  );

  useEffect(() => {
    cycleColor(0);
  }, [started]);

  useEffect(() => {
    if (phase === "show answer" && startAnswering) {
      if (red && color === theme.colors.primary) setBoxCount();
      else if (!red && color === "blue") setBoxCount();
    }
  }, [phase, color, startAnswering]);

  return (
    <motion.div
      onClick={() => {
        if (!randomShown.includes(i)) {
          if (phase !== "show answer") cycleColor();
          if (color === "rgba(223, 240, 255, 1)")
            setBoxCountFilled(boxCountFilled + 1);
          else if (color === "blue") {
            setBoxCountFilled(boxCountFilled - 1);
          }
        }
      }}
      style={{
        width: "8%",
        height: "8%",
        background:
          phase !== "show answer"
            ? "rgba(223, 240, 255, 1)"
            : red
            ? theme.colors.primary
            : "blue",
        position: "absolute",
        outline: `${small ? "3px" : "5px"} ${theme.colors.secondary} solid`,
        border: "3px rgba(223, 240, 255, 1) solid",
        top: y,
        left: x,
        margin: "auto",
        cursor: "pointer",
      }}
    >
      {startAnswering && (
        <div
          style={{
            width: "80%",
            height: "80%",
            background: randomShown.includes(i)
              ? red
                ? theme.colors.primary
                : "blue"
              : color,
            position: "absolute",
            right: 0,
            left: 0,
            top: "10%",
            margin: "auto",
          }}
        ></div>
      )}
    </motion.div>
  );
};

const GridcellReplicate = ({
  started,
  setResult,
  result,
  randomBoxes,
  startAnswering,
  setStartAnswering,
  simplified,
  phase,
  setPhase,
}) => {
  const { boxCount } = useReplicate();
  const [randomShown, setRandomShown] = useState([]);

  useEffect(() => {
    if (simplified) {
      setRandomShown(_.sampleSize(Array(...Array(20).keys()), 2));
    }
  }, [simplified]);

  useEffect(() => {
    if (started) {
      setPhase("show answer");
      setTimeout(() => {
        setPhase("answering");
        setStartAnswering(true);
      }, 6 * 1000);
    }
  }, [started]);

  useEffect(() => {
    if (startAnswering && !started) {
      const amountToWin = simplified ? 18 : 20;
      boxCount === amountToWin ? setResult(true) : setResult(false);
    }
  }, [boxCount, startAnswering, started]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "rgba(223, 240, 255, 1)",
      }}
    >
      {true &&
        randomBoxes.map((box, i) => {
          return (
            <Box
              i={i}
              simplified={simplified}
              randomShown={randomShown}
              started={started}
              startAnswering={startAnswering}
              phase={phase}
              key={i}
              x={box.x}
              y={box.y}
              red={i % 2 == 0 ? true : false}
            />
          );
        })}
      {result !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source src={`/${result ? "win" : "lose"}.mp4`} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default GridcellReplicate;
