import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import { useIdentical } from "../../store/games/identical";
import { theme } from "../../theme";

const PatternBox = ({ pattern, patternRound, index }) => {
  return (
    <motion.div
      style={{
        float: "left",
        width: "30%",
        height: "15%",
        margin: "1%",
        background: pattern[patternRound].includes(index)
          ? theme.colors.primary
          : "rgba(0,0,0,0)",
      }}
    />
  );
};

const GridcellIdentical = ({
  started,
  setStarted,
  resultRef,
  simplified,
  patternRound,
  setPatternRound,
  game,
  roundRef,
  setShowControls,
}) => {
  const { setResultIdentical, result } = useIdentical();

  useEffect(() => {
    if (started) {
      const int = setInterval(
        () => {
          if (
            roundRef.current === game.left.length ||
            resultRef.current !== undefined
          ) {
            clearInterval(int);
            if (resultRef.current === undefined) {
              setResultIdentical(false);
              setStarted(false);
              setShowControls(false);
              setPatternRound(0);
              roundRef.current = 0;
            }
          } else {
            roundRef.current++;
            setPatternRound((round) => round + 1);
          }
        },
        simplified ? 3 * 1000 : 2.5 * 1000
      );
    }
  }, [started]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "10px",
          position: "absolute",
          margin: "auto",
          left: 0,
          right: 0,
          backgroundColor: theme.colors.primary,
        }}
      />
      {roundRef.current !== game.left.length && started && (
        <Grid container style={{ height: "100%" }}>
          <Grid
            item
            style={{
              marginTop: 25,
              padding: "10px 10px 10px 20px",
            }}
            xs={6}
          >
            {Array.from(Array(18).keys()).map((i) => (
              <PatternBox
                patternRound={patternRound}
                pattern={game.left}
                started={started}
                key={i}
                index={i}
              />
            ))}
          </Grid>
          <Grid
            style={{
              marginTop: 25,
              padding: "10px 10px 10px 20px",
            }}
            item
            xs={6}
          >
            {Array.from(Array(18).keys()).map((i) => (
              <PatternBox
                patternRound={patternRound}
                pattern={game.right}
                started={started}
                key={i}
                index={i}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {result !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source src={`/${result ? "win" : "lose"}.mp4`} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

export default GridcellIdentical;
