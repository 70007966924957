import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Text } from "./typography";
import { useRef } from "react";
import useSound from "use-sound";
import boop from "../../assets/audio/boop.wav";

const CountDown = ({
  startCountdown,
  setStartCountdown,
  startGame,
  setResult,
}) => {
  const [count2, setCount] = useState(3);
  const count = useRef(3);
  const [hide, setHide] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [play] = useSound(boop);

  useEffect(() => {
    let interval;
    if (startCountdown && count.current !== 0) {
      setShowCount(true);
      setResult(undefined);
      setHide(false);
      play();
      interval = setInterval(() => {
        if (count.current === 1) {
          clearInterval(interval);
          setStartCountdown(false);
          setHide(true);
          setCount(3);
          count.current = 3;
          startGame();
        } else {
          play();
          setCount((count2) => count2 - 1);
          count.current -= 1;
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [startCountdown]);

  return (
    <AnimatePresence>
      {!hide && (
        <motion.div
          exit={{ opacity: 0 }}
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
        >
          <AnimatePresence exitBeforeEnter>
            {showCount && (
              <Text
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1.3 }}
                transition={{
                  scale: { duration: 1 },
                  opacity: { duration: 0.3 },
                }}
                exit={{ opacity: 0 }}
                key={count2}
                fontSize="calc(5rem + 3vw)"
                fontFamily="cube"
                light
              >
                {count2}
              </Text>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CountDown;
