import create from "zustand";

export const usePerimeter = create((set) => ({
  config: {
    speed: 3,
  },
  result: undefined,
  stopped: false,
  setResultPerimeter: (data) => set({ result: data }),
  setStopped: (data) => set({ stopped: data }),
}));
