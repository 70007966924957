import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";

import pathArrow from "../../assets/images/pathArrow.svg";
import { theme } from "../../theme";
import winSound from "../../assets/audio/win.wav";
import failSound from "../../assets/audio/fail.wav";
import useSound from "use-sound";
import { useStore } from "../../store";

export const Box = styled(motion.div)`
  width: 20%;
  height: 20%;
  position: relative;
  cursor: pointer;
  background: rgb(190, 223, 255);
  background: radial-gradient(
    circle,
    rgba(190, 223, 255, 1) 0%,
    rgba(223, 240, 255, 1) 68%,
    rgba(255, 255, 255, 1) 100%
  );
  float: left;
  border: 3px solid #64a4d3;
  display: flex;
  font-size: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #2a1a25;
  box-sizing: border-box;
`;

const GridcellPathfinder = ({
  pathDirections,
  inPath,
  path,
  started,
  index,
  currentTile,
  setCurrentTile,
  setResult,
  setStarted,
  pathwayHint,
  show,
  setShow,
  config,
  retry,
  startCountdown,
  stopGameMusic,
  setSimplifyRetries,
  simplifyRetries,
  simplified,
  GAEventsTracker,
}) => {
  const { soundSettingWin, soundSettingFail } = useStore();
  const [playFailMusic] = useSound(failSound, { volume: soundSettingFail });
  const [playWinMusic] = useSound(winSound, { volume: soundSettingWin });
  const [color, setColor] = useState("");
  const text = pathDirections[inPath];

  useEffect(() => {
    if (startCountdown) setColor(theme.colors.gradient);
    if (started) {
      if (simplified && simplifyRetries) {
        setShow(true);
        setTimeout(() => setShow(false), config.timePathShown * 1000);
      } else if (!retry) {
        setShow(true);
        setTimeout(() => setShow(false), config.timePathShown * 1000);
      }
    }
  }, [setShow, started, startCountdown, simplifyRetries]);

  const getBorder = () => {
    if (started) {
      if (text === "start") return "#ae1f23 solid 7px";
      if (text === "end") return `${theme.colors.secondary} solid 7px`;
    }
  };

  const checkTile = (current, next) => {
    var currinPath = path.indexOf(current);
    var nextInPath = path.indexOf(next);

    if (text === "start") {
      setColor(
        "radial-gradient(circle, rgba(207,252,77,1) 0%, rgba(179,249,85,1) 50%, rgba(144,226,80,1) 80%, rgba(86,233,48,1) 100%)"
      );
      return;
    }

    if (currinPath >= 0) {
      if (nextInPath >= 0 && nextInPath === currinPath + 1) {
        if (nextInPath !== path.length - 1 || text === "start") {
          setColor(
            "radial-gradient(circle, rgba(207,252,77,1) 0%, rgba(179,249,85,1) 50%, rgba(144,226,80,1) 80%, rgba(86,233,48,1) 100%)"
          );
        } else {
          setResult(true);
          setColor(theme.colors.gradient);
          setStarted(false);
          stopGameMusic();
          playWinMusic();
          GAEventsTracker("Game Won");
        }
      } else {
        if (simplified) setSimplifyRetries(simplifyRetries - 1);
        setStarted(false);
        setColor(theme.colors.gradient);
        setResult(false);
        stopGameMusic();
        playFailMusic();
        GAEventsTracker("Game Failed");
      }
    } else {
      if (simplified) setSimplifyRetries(simplifyRetries - 1);
      setStarted(false);
      setColor(theme.colors.gradient);
      setResult(false);
      stopGameMusic();
      playFailMusic();
      GAEventsTracker("Game Failed");
    }
    setCurrentTile(index);
  };

  const getRotation = () => {
    switch (text) {
      case "right":
        return "rotate()";
      case "left":
        return "rotate(180deg)";
      case "up":
        return "rotate(270deg)";
      case "down":
        return "rotate(90deg)";
      default:
        break;
    }
  };

  return (
    <Box
      onClick={() =>
        color !==
          "radial-gradient(circle, rgba(207,252,77,1) 0%, rgba(179,249,85,1) 50%, rgba(144,226,80,1) 80%, rgba(86,233,48,1) 100%)" &&
        checkTile(currentTile, index)
      }
      style={{
        border: getBorder(),
        background: color,
        pointerEvents: started && !show ? "all" : "none",
      }}
    >
      <AnimatePresence exitBeforeEnter>
        {show && text && text !== "start" && text !== "end" && (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
              delay: path.indexOf(index) * 0.05,
            }}
            alt="arrow"
            style={{ transform: getRotation() }}
            width={"30%"}
            height="auto"
            src={pathArrow}
          />
        )}
        {pathwayHint.includes(index) && !show && (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            alt="arrow"
            style={{ transform: getRotation(), pointerEvents: "none" }}
            width={"30%"}
            height="auto"
            src={pathArrow}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};

export default GridcellPathfinder;
