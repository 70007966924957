export function checkPerimeterResult(rect1, rect2, offset) {
  console.log(rect1, rect2, offset);

  if (rect1.left + offset < rect2.left) {
    if (rect1.top + offset < rect2.top) {
      if (rect1.bottom - offset > rect2.bottom) {
        if (rect1.right - offset > rect2.right) {
          return true;
        } else {
          return false;
        }
      } else return false;
    } else return false;
  } else return false;
}
