import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { getRandomArbitrary } from "../../gameFunctions/calculate";
import { theme } from "../../theme";
import { useRepetition } from "../../store/games/repetition";
import styled from "styled-components";

const BoxStyled = styled(motion.div)`
  &:after {
    content: "";
    clear: both;
  }
`;

const Box = ({ index, activeTile, simplified }) => {
  const checkSimplified = () => {
    if (simplified) {
      if (index === 14 || index === 15) return "rgba(0,0,0,0.3)";
      else {
        return index === activeTile
          ? ["rgba(0,0,0,0)", theme.colors.primary, "rgba(0,0,0,0)"]
          : "rgba(0,0,0,0)";
      }
    } else {
      return index === activeTile
        ? ["rgba(0,0,0,0)", theme.colors.primary, "rgba(0,0,0,0)"]
        : "rgba(0,0,0,0)";
    }
  };

  return (
    <BoxStyled
      transition={{ duration: 1.5 - 0.5 }}
      animate={{
        background: checkSimplified(),
      }}
      style={{
        width: "25%",
        height: "25%",
        float: "left",
        border: `5px solid ${theme.colors.secondary}`,
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
      }}
    />
  );
};

const GridcellRepetition = ({
  started,
  activeTile,
  setActiveTile,
  shownTiles,
  setShownTiles,
  resultRef,
  resultFeedback,
  simplified,
  clickedRef,
  startChecking,
  setStartChecking,
  result,
}) => {
  const { setResultRepetition } = useRepetition();
  const [amountOfTiles, setAmountOfTiles] = useState(15);
  const [max, setMax] = useState();
  function hasDuplicates(arr) {
    return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
  }

  const generateNumber = (unwanted) => {
    let random = getRandomArbitrary(0, amountOfTiles);
    if (random === unwanted)
      return random >= 0 && random <= 10 ? random + 1 : random - 1;
    else {
      return random;
    }
  };

  useEffect(() => {
    let interval;
    if (started && clickedRef.current === false) {
      setAmountOfTiles(simplified ? 13 : 15);
      interval = setInterval(() => {
        if (
          clickedRef.current === true ||
          resultRef.current !== undefined ||
          hasDuplicates(shownTiles)
        ) {
          if (!hasDuplicates(shownTiles)) {
            resultRef.current = false;
            setResultRepetition(false);
            setStartChecking(false);
            setMax(undefined);
          }
          clearInterval(interval);
        } else {
          const random = generateNumber(shownTiles[shownTiles.length - 1]);
          setActiveTile(random);
          setShownTiles((tiles) => [...tiles, random]);
        }
      }, 1.5 * 1000);
    }

    return () => clearInterval(interval);
  }, [started, clickedRef.current, resultRef.current, shownTiles]);

  useEffect(() => {
    if (
      hasDuplicates(shownTiles) &&
      result === undefined &&
      max === undefined
    ) {
      setMax(shownTiles.length);
      setTimeout(() => {
        setStartChecking(true);
      }, 2000);
    } else if (clickedRef.ref === true) {
      resultRef.current = false;
      setResultRepetition(false);
    }
  }, [shownTiles, clickedRef.current, result]);

  useEffect(() => {
    if (hasDuplicates(shownTiles) && startChecking) {
      if (clickedRef.current === false && startChecking) {
        resultRef.current = false;
        setResultRepetition(false);
        setStartChecking(false);
        setMax(undefined);
      } else if (clickedRef.current === true && startChecking) {
        resultRef.current = true;
        setResultRepetition(true);
        setStartChecking(false);
        setMax(undefined);
      }
    }
  }, [clickedRef.current, shownTiles, startChecking, setStartChecking]);

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {Array.from(Array(16).keys()).map((i) => {
          return (
            <Box simplified={simplified} activeTile={activeTile} index={i} />
          );
        })}
      </div>
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default GridcellRepetition;
