import React, { useEffect } from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { theme } from "../../theme";
import { useExact } from "../../store/games/exact";

const TimeBar = ({ time, simplified }) => {
  return (
    <motion.div
      style={{
        top: 50,
        height: 50,
        position: "absolute",
        border: `3px ${theme.colors.primary} solid`,
        backgroundColor: "rgba(0,0,0,0)",
        width: "60%",
        padding: "3px",
      }}
    >
      <motion.div
        animate={{
          width: simplified
            ? `${Math.round((time / 5.25) * 100)}%`
            : `${Math.round((time / 4.75) * 100)}%`,
        }}
        style={{
          backgroundColor: theme.colors.primary,
          height: "100%",
        }}
      />
    </motion.div>
  );
};

const GridcellExact = ({
  started,
  timeRef,
  resultFeedback,
  setResult,
  resultRef,
  setTime,
  time,
  clicksRef,
  simplified,
  startedTime,
  setStartedTime,
}) => {
  const { result } = useExact();

  useEffect(() => {
    if (startedTime) {
      const interval = setInterval(() => {
        if (timeRef.current <= 0) {
          if (clicksRef.current === 25) setResult(true);
          else setResult(false);
          setStartedTime(false);
          clearInterval(interval);
        } else {
          setTime((time) => (time -= 0.01));
          timeRef.current -= 0.01;
        }
      }, 10);
    }
  }, [started, resultRef.current, startedTime]);

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container alignItems="center" justify="center">
        <Grid item>
          <span
            style={{
              fontSize: "4rem",
              color: theme.colors.primary,
            }}
          >
            {result !== undefined ? clicksRef.current : "...."}
          </span>
        </Grid>
      </Grid>
      <TimeBar simplified={simplified} time={time} />
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default GridcellExact;
