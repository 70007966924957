import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Grid from "@bit/mui-org.material-ui.grid";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import useSound from "use-sound";
import ReactGa from "react-ga";

import { Container } from "../../atoms/Container";
import GameSkeleton from "../../atoms/GameSkeleton";
import { transition } from "../../organisms/GameControls";
import { theme } from "../../../theme";
import GameInterface from "../../organisms/GameInterface";
import GridcellExact from "../../gamecomponents/GridcellExact";
import { useStore } from "../../../store";
import { useExact } from "../../../store/games/exact";
import GameControls from "../../organisms/GameControls";
import MobileGameControls from "../../organisms/MobileGameControls";
import gameMusic from "../../../assets/audio/game.mp3";
import winSound from "../../../assets/audio/win.wav";
import failSound from "../../../assets/audio/fail.wav";
import BackButton from "../../atoms/BackButton";
import { useGAEventsTracker } from "../../../hooks/useGAEventsTracker";

const Exact = () => {
  const {
    games,
    setSelectedGame,
    selectedGame,
    soundSettingGame,
    soundSettingWin,
    soundSettingFail,
  } = useStore();
  const { result, setResultExact } = useExact();
  const [resultFeedback, setResultFeedback] = useState(undefined);
  const [started, setStarted] = useState(false);
  const [simplified, setSimplified] = useState(false);
  const [open, setOpen] = useState(true);
  const { xs } = useWindowDimensions();
  const history = useHistory();
  let resultRef = useRef(undefined);
  let clicksRef = useRef(0);
  const [startCountdown, setStartCountdown] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [startedTime, setStartedTime] = useState(false);
  const [playGameMusic, { stop }] = useSound(gameMusic, {
    volume: soundSettingGame,
  });
  const [playWinMusic] = useSound(winSound, { volume: soundSettingWin });
  const [playFailMusic] = useSound(failSound, { volume: soundSettingFail });
  const GAEventsTracker = useGAEventsTracker("Exact");

  let timeRef = useRef(4.75);
  const [time, setTime] = useState(4.75);

  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  useEffect(() => {
    if (simplified) {
      setTime(5.25);
      timeRef.current = 5.25;
    }
  }, [simplified]);

  const startGame = () => {
    playGameMusic();
    clicksRef.current = 0;
    timeRef.current = simplified ? 5.25 : 4.75;
    setTime(simplified ? 5.25 : 4.75);
    setStarted(true);
    setResultFeedback(undefined);
    setResultExact(undefined);
    GAEventsTracker("Game started");
  };

  useEffect(() => {
    if (result) {
      setTimeout(() => {
        setStarted(false);
        setResultFeedback(true);
        setShowControls(false);
        stop();
        playWinMusic();
        GAEventsTracker("Game Won");
      }, 2000);
    } else if (result === false) {
      setTimeout(() => {
        setResultFeedback(false);
        setStarted(false);
        setShowControls(false);
        stop();
        playFailMusic();
        GAEventsTracker("Game Failed");
      }, 2000);
    }
  }, [result]);

  useEffect(() => {
    setSelectedGame(
      games.find((game) => game.link === history.location.pathname)
    );
    document.body.style.overflowY = "hidden";
    document.body.style.overflowX = "hidden";

    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    !xs && setOpen(true);
    if (startCountdown && result === undefined && xs) setOpen(false);
    if (result !== undefined && xs) setOpen(false);
  }, [xs, setOpen, startCountdown, result]);

  return (
    <Container center fluid>
      <BackButton />
      <Grid container alignItems="center">
        <Grid
          style={{
            height: xs ? "auto" : "100vh",
          }}
          item
          xs={12}
          md={8}
        >
          <GameSkeleton
            started={started}
            style={{
              marginTop: "5em",
            }}
          >
            <GridcellExact
              setStartedTime={setStartedTime}
              startedTime={startedTime}
              time={time}
              clicksRef={clicksRef}
              timeRef={timeRef}
              setTime={setTime}
              resultFeedback={resultFeedback}
              setResult={setResultExact}
              resultRef={resultRef}
              result={result}
              started={started}
              setStarted={setStarted}
              startGame={startGame}
              simplified={simplified}
            />
          </GameSkeleton>
        </Grid>
        <Grid item xs={4}>
          <motion.div
            transition={transition}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: xs ? 20 : 1,
              overflowY: "auto",
              height: "100vh",
              height: "-webkit-fill-available",
            }}
            exit={{ x: 0, transition: { delay: 0.5 } }}
            initial={{
              x: 0,
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              height: "-webkit-fill-available",
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
            animate={{
              x: xs ? (open ? 0 : -20) : 0,
              y: xs ? (open ? 0 : 20) : 0,
              height: xs ? (open ? "100vh" : "40px") : "100vh",
              height: xs ? (open ? "-webkit-fill-available" : "40px") : "100vh",
              width: xs ? (open ? "100vw " : "75px") : "34vw",
              backgroundColor: xs
                ? open
                  ? theme.colors.primary
                  : theme.colors.secondary
                : theme.colors.primary,
              boxShadow: `0 5px 0 ${theme.colors.secondaryDarker}`,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              {showControls && !xs ? (
                <GameControls
                  differentText={selectedGame?.differentText}
                  simplified={simplified}
                  simplify={() => setSimplified(true)}
                  type={selectedGame?.type}
                  setShowControls={setShowControls}
                  onClick={() => {
                    if (started && !startedTime) {
                      setStartedTime(true);
                    }
                    started && result === undefined && clicksRef.current++;
                  }}
                />
              ) : (
                <GameInterface
                  setShowControls={setShowControls}
                  started={started}
                  result={result}
                  startGame={() => {
                    setResultExact(undefined);
                    setResultFeedback(undefined);
                    setShowControls(true);
                    startGame();
                    setOpen(false);
                  }}
                  simplify={() => setSimplified(true)}
                  setOpen={setOpen}
                  open={open}
                  simplified={simplified}
                />
              )}
            </AnimatePresence>
          </motion.div>
        </Grid>
        {xs && (
          <MobileGameControls
            clickable={!started}
            differentText={selectedGame?.differentText}
            onClick={() => {
              if (started && !startedTime) {
                setStartedTime(true);
              }
              started && result === undefined && clicksRef.current++;
            }}
            simplify={() => setSimplified(true)}
            simplified={simplified}
            startGame={() => {
              setResultExact(undefined);
              setResultFeedback(undefined);
              setShowControls(true);
              startGame();
              setOpen(false);
            }}
            started={started}
            result={result}
            type={selectedGame?.type}
          />
        )}
      </Grid>
    </Container>
  );
};

export default Exact;
