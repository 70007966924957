import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useDimension } from "../../store/games/dimension";
import { theme } from "../../theme";

const TargetDiamond = React.forwardRef((props, ref) => {
  const variants = {
    show: {
      opacity: 1,
    },
    hide: {
      opacity: 0,
    },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      animate={props.controls}
      style={{
        position: "absolute",
        width: "40%",
        height: "40%",
        border: `${props.simplified ? "17px" : "10px"} blue solid`,
        transform: "rotateZ(45deg)",
      }}
    />
  );
});

const TargetDiamondInner = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        width: "40%",
        height: "40%",
        transform: "rotateZ(45deg)",
      }}
    />
  );
});

const PlayerDiamond = React.forwardRef((props, ref) => {
  const { setResultDimension } = useDimension();

  const variants = {
    shrink: {
      scale: 0,
      opacity: 1,
      transition: {
        type: "tween",
        ease: "linear",
        duration: 10,
        opacity: { duration: 0.5 },
      },
    },
    hide: {
      opacity: 0,
    },
    reset: {
      scale: 1.2,
      opacity: 0,
      transition: { duration: 0.5 },
    },
  };

  return (
    <motion.div
      onAnimationComplete={(def) => {
        def === "shrink" && setResultDimension(false);
      }}
      ref={ref}
      variants={variants}
      animate={props.controls}
      style={{
        width: "100%",
        height: "100%",
        opacity: 0,
        backgroundColor: theme.colors.primary,
        scale: 1.15,
        rotateZ: 45,
      }}
    />
  );
});

const GridcellDimension = ({
  started,
  resultFeedback,
  config,
  simplified,
  controlsPlayer,
  controlsTarget,
  playerRef,
  targetRef,
  targetInnerRef,
}) => {
  const { result } = useDimension();

  useEffect(() => {
    if (started) {
      setTimeout(() => {
        controlsPlayer.start("shrink");
        controlsTarget.start("hide");
      }, 2000);
    } else {
      controlsPlayer.start("reset");
      controlsTarget.start("show");
    }
  }, [started]);

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <PlayerDiamond
        started={started}
        config={config}
        ref={playerRef}
        controls={controlsPlayer}
      />
      <TargetDiamond
        simplified={simplified}
        ref={targetRef}
        controls={controlsTarget}
      />
      <TargetDiamondInner ref={targetInnerRef} />
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default GridcellDimension;
