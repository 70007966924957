import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { isTablet, isMobileOnly } from "react-device-detect";
import ReactGA from "react-ga";

import { theme } from "./theme";
import Perimeter from "./components/pages/games/Perimeter";
import Pathfinder from "./components/pages/games/Pathfinder";
import VerticalDrop from "./components/pages/games/VerticalDrop";
import TimeFreeze from "./components/pages/games/TimeFreeze";
import Calculate from "./components/pages/games/Calculate";
import Circumvent from "./components/pages/games/Circumvent";
import Identical from "./components/pages/games/Identical";
import StopZone from "./components/pages/games/StopZone";
import Replicate from "./components/pages/games/Replicate";
import Avoid from "./components/pages/games/Avoid";
import Dimension from "./components/pages/games/Dimension";
import Exact from "./components/pages/games/Exact";
import TimeSplit from "./components/pages/games/TimeSplit";
import Repetition from "./components/pages/games/Repetition";
import NewMenu from "./components/pages/menu/NewMenu";
import ColourCount from "./components/pages/games/ColourCount";
import { useStore } from "./store";
import useWindowDimensions from "./hooks/useWindowDimensions";
import TurnDevice from "./components/atoms/TurnDevice";

ReactGA.initialize("UA-200683409-1");

function App() {
  const setTouchDevice = useStore((state) => state.setTouchDevice);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setTouchDevice(
      "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route
          render={({ location }) => {
            return isMobileOnly &&
              height < width &&
              location.pathname !== "/" ? (
              <TurnDevice mode="portrait" />
            ) : isTablet && height > width && location.pathname !== "/" ? (
              <TurnDevice mode="landscape" />
            ) : (
              <>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/" component={NewMenu} />
                  <Route exact path="/perimeter" component={Perimeter} />
                  <Route exact path="/pathfinder" component={Pathfinder} />
                  <Route exact path="/timefreeze" component={TimeFreeze} />
                  <Route exact path="/calculate" component={Calculate} />
                  <Route exact path="/circumvent" component={Circumvent} />
                  <Route exact path="/identical" component={Identical} />
                  <Route exact path="/stopzone" component={StopZone} />
                  <Route exact path="/replicate" component={Replicate} />
                  <Route exact path="/avoid" component={Avoid} />
                  <Route exact path="/dimension" component={Dimension} />
                  <Route exact path="/exact" component={Exact} />
                  <Route exact path="/timeSplit" component={TimeSplit} />
                  <Route exact path="/repetition" component={Repetition} />
                  <Route exact path="/colour-count" component={ColourCount} />
                  <Route exact path="/vertical-drop" component={VerticalDrop} />
                </Switch>
              </>
            );
          }}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
