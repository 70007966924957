import create from "zustand";

export const usePathfinder = create((set) => ({
  config: {
    timePathShown: 3,
  },
  differentPaths: [
    {
      path: [2, 1, 0, 5, 10, 15, 16, 11, 12, 17, 18, 19, 24, 23],
      pathDirections: [
        "start",
        "left",
        "down",
        "down",
        "down",
        "right",
        "up",
        "right",
        "down",
        "right",
        "right",
        "down",
        "left",
        "end",
      ],
      hints: [5, 15],
    },
    {
      path: [0, 1, 2, 7, 6, 5, 10, 15, 16, 21, 22, 23, 18, 19, 24],
      pathDirections: [
        "start",
        "right",
        "down",
        "left",
        "left",
        "down",
        "down",
        "right",
        "down",
        "right",
        "right",
        "up",
        "right",
        "down",
        "end",
      ],
      hints: [16, 23],
    },
    {
      path: [1, 2, 3, 4, 9, 14, 13, 12, 7, 6, 11, 10, 15, 20, 21],
      pathDirections: [
        "start",
        "right",
        "right",
        "down",
        "down",
        "left",
        "left",
        "up",
        "left",
        "down",
        "left",
        "down",
        "down",
        "right",
        "end",
      ],
      hints: [20, 11],
    },
    {
      path: [3, 2, 1, 6, 5, 10, 11, 12, 13, 18, 23, 22, 21, 20],
      pathDirections: [
        "start",
        "left",
        "down",
        "left",
        "down",
        "right",
        "right",
        "right",
        "down",
        "down",
        "left",
        "left",
        "left",
        "end",
      ],
      hints: [22, 12],
    },
    {
      path: [4, 9, 14, 13, 8, 3, 2, 1, 6, 11, 12, 17, 16, 21, 22],
      pathDirections: [
        "start",
        "down",
        "left",
        "up",
        "up",
        "left",
        "left",
        "down",
        "down",
        "right",
        "down",
        "left",
        "down",
        "right",
        "end",
      ],
      hints: [1, 13],
    },
    {
      path: [5, 0, 1, 2, 3, 8, 9, 14, 13, 12, 17, 16, 21, 22, 23, 24],
      pathDirections: [
        "start",
        "right",
        "right",
        "right",
        "down",
        "right",
        "down",
        "left",
        "left",
        "down",
        "left",
        "down",
        "right",
        "right",
        "right",
        "end",
      ],
      hints: [17, 2],
    },
    {
      path: [20, 21, 16, 15, 10, 5, 6, 7, 12, 13, 18, 19, 14, 9, 4],
      pathDirections: [
        "start",
        "up",
        "left",
        "up",
        "up",
        "right",
        "right",
        "down",
        "right",
        "down",
        "right",
        "up",
        "up",
        "up",
        "end",
      ],
      hints: [6, 15],
    },
    {
      path: [15, 10, 5, 0, 1, 2, 7, 8, 9, 14, 13, 18, 17, 22, 23, 24],
      pathDirections: [
        "start",
        "up",
        "up",
        "right",
        "right",
        "down",
        "right",
        "right",
        "down",
        "left",
        "down",
        "left",
        "down",
        "right",
        "right",
        "end",
      ],
      hints: [8, 14],
    },
    {
      path: [20, 21, 16, 15, 10, 11, 12, 17, 22, 23, 24, 19, 18, 13, 8, 9, 14],
      pathDirections: [
        "start",
        "up",
        "left",
        "up",
        "right",
        "right",
        "down",
        "down",
        "right",
        "right",
        "up",
        "left",
        "up",
        "up",
        "right",
        "down",
        "end",
      ],
      hints: [9, 10],
    },
    {
      path: [15, 10, 11, 6, 1, 2, 3, 8, 13, 18, 17, 22, 23, 24, 19, 14, 9],
      pathDirections: [
        "start",
        "right",
        "up",
        "up",
        "right",
        "right",
        "down",
        "down",
        "down",
        "left",
        "down",
        "right",
        "right",
        "up",
        "up",
        "up",
        "end",
      ],
      hints: [1, 19],
    },
    {
      path: [10, 15, 20, 21, 22, 17, 18, 13, 12, 11, 6, 1, 2, 3, 8, 9],
      pathDirections: [
        "start",
        "down",
        "right",
        "right",
        "up",
        "right",
        "up",
        "left",
        "left",
        "up",
        "up",
        "right",
        "right",
        "down",
        "right",
        "end",
      ],
      hints: [1, 13],
    },
    {
      path: [0, 1, 2, 3, 4, 9, 8, 7, 12, 17, 16, 21, 22, 23, 24, 19],
      pathDirections: [
        "start",
        "right",
        "right",
        "right",
        "down",
        "left",
        "left",
        "down",
        "down",
        "left",
        "down",
        "right",
        "right",
        "right",
        "up",
        "end",
      ],
      hints: [2, 16],
    },
    {
      path: [21, 16, 11, 10, 5, 6, 7, 2, 3, 4, 9, 8, 13, 18, 19, 14],
      pathDirections: [
        "start",
        "up",
        "left",
        "up",
        "right",
        "right",
        "up",
        "right",
        "right",
        "down",
        "left",
        "down",
        "down",
        "right",
        "up",
        "end",
      ],
      hints: [5, 9],
    },
    {
      path: [23, 22, 21, 16, 15, 10, 11, 12, 13, 8, 3, 2, 7, 6, 5, 0],
      pathDirections: [
        "start",
        "left",
        "up",
        "left",
        "up",
        "right",
        "right",
        "right",
        "up",
        "up",
        "left",
        "down",
        "left",
        "left",
        "up",
        "end",
      ],
      hints: [10, 12],
    },
    {
      path: [24, 19, 14, 13, 12, 7, 2, 1, 6, 11, 16, 15, 10, 5, 0],
      pathDirections: [
        "start",
        "up",
        "left",
        "left",
        "up",
        "up",
        "left",
        "down",
        "down",
        "down",
        "left",
        "up",
        "up",
        "up",
        "end",
      ],
      hints: [10, 14],
    },
  ],
  result: undefined,
  setResultPathfinder: (data) => set({ result: data }),
}));
