import _ from "lodash";
const { getRandomArbitrary } = require("./calculate");

export const generateGame = () => {
  const identical = _.range(0, getRandomArbitrary(6, 10));

  const left = [];
  const right = [];
  for (let index = 0; index < identical.length; index++) {
    const random = _.sampleSize(_.range(6, 10), 2);
    right.push(
      _.sampleSize(
        _.range(0, 17),
        index === identical.length - 1 ? identical.length : random[0]
      )
    );
    left.push(
      _.sampleSize(
        _.range(0, 17),
        index === identical.length - 1 ? identical.length : random[1]
      )
    );
  }

  return { left, right };
};
