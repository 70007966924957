import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";
import useSound from "use-sound";

import { Text, ButtonText } from "../atoms/typography";
import { Button } from "../atoms/Button";
import { theme } from "../../theme";
import clickSound from "../../assets/audio/click.wav";
import { useStore } from "../../store";

const InterfaceActions = ({
  selectedGame,
  simplify,
  startGame,
  result,
  started,
  simplified,
}) => {
  const soundSettingClick = useStore((state) => state.soundSettingClick);
  const [click] = useSound(clickSound, { volume: soundSettingClick });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.5 } }}
    >
      <Grid container spacing={2} style={{ marginBottom: "1em" }}>
        <Grid item xs={12}>
          <Text style={{ letterSpacing: 2 }} fontSize="1rem" light>
            SIMPLIFY
          </Text>
          <Text fontFamily="graphik" fontSize="calc(1rem + 0.2vw)" light>
            {selectedGame?.simplify}
          </Text>
        </Grid>
        {!started && (
          <Grid item xs={6}>
            <Button
              exit={{ opacity: 0 }}
              fullWidth
              onTapStart={() => click()}
              onClick={() => startGame()}
            >
              <ButtonText fontSize="calc(0.7rem + 0.3vw)">
                {result === false ? "RETRY" : "START"}
              </ButtonText>
            </Button>
          </Grid>
        )}
        <Grid item xs={started ? 12 : 6}>
          <Button
            animate={{
              background: simplified ? "rgba(0,0,0,0)" : theme.colors.secondary,
            }}
            fullWidth
            onTapStart={() => click()}
            onClick={() => simplify()}
          >
            <ButtonText fontSize="calc(0.7rem + 0.3vw)">
              {simplified ? "SIMPLIFIED" : "SIMPLIFY"}
            </ButtonText>
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default InterfaceActions;
