export const theme = {
  colors: {
    primary: "rgba(174,31,35,1)",
    secondary: "#C3914C",
    secondaryDarker: "#96703b",
    default: "#bababa",
    light: "#fff",
    win: "#2f963c",
    gradient:
      "radial-gradient(circle,rgba(190, 223, 255, 1) 0%,rgba(223, 240, 255, 1) 68%,rgba(255, 255, 255, 1) 100%)",
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};
