import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { theme } from "../../theme";

const GridcellTimeSplit = ({ time, resultFeedback }) => {
  let centiseconds = ("0" + (Math.round(time / 100) % 100)).slice(-3);

  const last = time.toString();
  const sub = last.substring(last.length - 3);
  const last3 = parseInt(sub);
  const secondDigit = Math.floor(time / 1000) % 60;
  let seconds = ("0" + (Math.floor(time / 1000) % 60)).slice(-2);
  const getLastDigit = (num) => +(num + "").slice(-1);

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "100%",
        background: theme.colors.gradient,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          fontSize: "5vw",
          fontWeight: 900,
          color: theme.colors.primary,
        }}
        container
        alignItems="center"
        justify="center"
      >
        <div
          style={{
            padding: "1rem",
            border: `${theme.colors.primary} 10px solid`,
            width: "70%",
            display: "flex",
            justifyContent: "space-around",
            textAlign: "center",
          }}
        >
          <Grid item xs={6}>
            <span>
              {last3 >= 950 ? seconds.replace(/.$/, secondDigit + 1) : seconds}
            </span>
          </Grid>
          <span style={{ position: "absolute" }}>&nbsp;.&nbsp;</span>
          <Grid item xs={6}>
            <span>{getLastDigit(centiseconds)}0</span>
          </Grid>
        </div>
      </Grid>
      {resultFeedback !== undefined && (
        <video
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            zIndex: 5,
          }}
          loop
          muted
          playsInline
        >
          <source
            src={`/${resultFeedback ? "win" : "lose"}.mp4`}
            type="video/mp4"
          />
        </video>
      )}
    </div>
  );
};

export default GridcellTimeSplit;
