import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: ${document.documentElement.scrollHeight}px;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x:hidden;
 
  @media (min-width: 568px) {
    .container {
      width: 550px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }

  ${(props) =>
    props.fluid &&
    css`
      width: 100vw;
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}


    ${(props) =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `}
`;
