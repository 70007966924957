import React from "react";
import Grid from "@bit/mui-org.material-ui.grid";
import { motion } from "framer-motion";

import { Text, BigHeader } from "../atoms/typography";

const InterfaceGameInfo = ({ selectedGame }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.5 } }}
    >
      <Grid container>
        <Grid item xs={12} md={10}>
          <Text style={{ letterSpacing: 2 }} fontSize="1rem" light>
            GAME {selectedGame?.id}
          </Text>
          <BigHeader fontSize="calc(1rem + 1.5vw)" light>
            {selectedGame?.name}
          </BigHeader>
          <Text
            style={{ letterSpacing: 2, marginTop: 20 }}
            fontSize="1rem"
            light
          >
            HOW TO PLAY
          </Text>
          <Text
            fontFamily="graphik"
            fontSize="calc(1rem + 0.2vw)"
            style={{ lineHeight: 1.5 }}
            light
          >
            {selectedGame?.explanation}
          </Text>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default InterfaceGameInfo;
